import config from '@/utils/config'
import RequestAdmin from '@/utils/request'
import { formatDate } from '@/utils/fechas'

const url = `${config.URL_BASE}coordinacion-aseguradoras`

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateCoordinacion(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Coordinación', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findCoordinacion(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Coordinaciones', err)
    return null
  }
}

export async function countCoordinacion(where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener Coordinaciones', err)
    return null
  }
}

export async function getCoordinacion(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Coordinación', err)
    return null
  }
}

function getNombresAccesorios(detalle) {
  const { datosAccesorios } = detalle

  if (datosAccesorios && Array.isArray(datosAccesorios.accesoriosAdicionales)) {
    const nombres = datosAccesorios.accesoriosAdicionales.map(accesorio => accesorio.nombre)
    return nombres.join(', ')
  }

  return ''
}

export async function generarReporte(coordinacion) {
  const request = new RequestAdmin()
  const documentos = []

  // eslint-disable-next-line no-restricted-syntax
  for (const detalle of coordinacion.detalleAccidente) {
    const i = coordinacion.detalleAccidente.indexOf(detalle)
    const accesoriosNombres = getNombresAccesorios(detalle)
    // eslint-disable-next-line no-use-before-define
    const html = generarHtmlParaDetalle(coordinacion, detalle, accesoriosNombres, i)

    const body = {
      tamaño: 'A2',
      margenes: {
        top: '2cm',
        right: '2cm',
        bottom: '2cm',
        left: '2cm',
      },
      orientation: 'portrait',
      contenido: html,
    }

    const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
    documentos.push(result)
  }

  return documentos
}

function obtenerValorDetalle(detalle, propiedad, subPropiedades, tipoDato) {
  if (!detalle) return ''

  let valor = detalle

  // eslint-disable-next-line no-restricted-syntax
  for (const subPropiedad of subPropiedades) {
    if (valor && valor[subPropiedad]) {
      valor = valor[subPropiedad]
    } else {
      return ''
    }
  }

  if (tipoDato === 'foto') {
    return valor && valor.foto ? valor.foto.url : ''
  }
  // eslint-disable-next-line no-prototype-builtins
  return valor.hasOwnProperty(propiedad) ? valor[propiedad] : ''
}
function generarHtmlParaDetalle(coordinacion, detalle, accesoriosNombres) {
  const nombreAjustador = coordinacion.nombreAjustador || ''
  const tipoAccidente = coordinacion.tipoAccidente.nombre || ''
  const fechaCreacion = formatDate(coordinacion.fechaCreacion, '/') || ''
  const { observacionGeneral } = coordinacion
  return `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE COORDINACIÓN ASEGURADORA</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headLogos{
      width: 100%;
      margin-bottom: 5px;
      border-collapse: collapse;
    }
    .letrasLogo{
      background-color: #1f88c2;
      color: white;
      font-family: sans-serif;
    }
    .tabla{
      border-collapse: collapse;
      width: 100%;
      font-family: sans-serif;
    }
    .tituloDatos {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      padding: 3px;
      width: 480px;
    }
    .tituloDetalle {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      padding: 3px;
    }
    th{
      border: 1px solid black;
      font-family: sans-serif;
    }
    td{
      font-family: sans-serif;
      padding-right: 15px;
      padding-left: 15px;
    }
    .Imagenes{
      width: 100%;
      border-collapse: collapse;
    }
    .table {
      border: 1px solid black;
      border-collapse: collapse;
      width: 100%;
      padding-right: 50px;
    }
    .lineaFirma2 {
      border-top: 1px solid black;
      margin-left: auto;
      margin-right: auto;
      width: 40%;
    }
    .lineaFirma3 {
      border-top: 1px solid black;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    .lineaFirma{
      border-top: 1px solid black;
      width: 25%;
      display: flex;
      margin-top: 150px;
      font-family: arial,serif;
    }
    .linea {
      position: relative;
      margin-top: 40px;
      width: 100%;
    }
    .linea hr {
      border: none;
      border-top: 1px solid black;
      margin-bottom: 15px;
    }
    .texto {
      text-transform: uppercase;
      text-align: center;
      font-family: arial,serif;
    }
    .centro {
      display: flex;
    }
    .linea span {
      width: 100%;
      position: absolute;
      top: -15px;
      transform: translate(-50%, -50%);
    }
    .poste-cell {
      border: 1px solid black;
      padding: 10px;
      text-align: center;
  }
  .tituloDatosImagenes {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 66.3%;
      text-align: center;
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid black;
      margin-left: 15%;
  }
  .tituloDatosImagenes2 {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 66%;
      text-align: center;
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid black;
      margin-left: 15%;
  }
  .tablaImagenesVFOR {
      border-collapse: collapse;
      width: 100%;
    }
    .tablaImagenesVFOR td {
      width: 50%;
      padding: 10px;
      box-sizing: border-box;
    }
    .tablaImagenesVFOR img {
      width: 480px;
      max-height: 480px;
      max-width: 480px;
      height: 480px;
      margin-bottom: 15px;
      margin-left: 15%;
    }
    .tituloDatosImagenesSeguimiento {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 95.6%;
      text-align: center;
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid black;
      margin-left: 42.5%;
    }
    .tablaImagenesVFORSeguimiento td {
      width: 50%;
      padding: 10px;
      box-sizing: border-box;
    }
    .tablaImagenesVFORSeguimiento img {
      width: 480px;
      max-height: 480px;
      max-width: 480px;
      height: 480px;
      margin-bottom: 15px;
      margin-left: 42.5%;
    }
    .tituloDatosImagenesSeguimientoUnico {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 95.6%;
      text-align: center;
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid black;
      margin-left: 97%;
    }
    .tablaImagenesVFORSeguimientoUnico img {
      width: 480px;
      max-height: 480px;
      max-width: 480px;
      height: 480px;
      margin-bottom: 15px;
      margin-left: 97%;
    }
    .salto-pagina {
      page-break-before: always;
    }
</style>
<body>
<table class="headLogos">
  <tr>
    <th width="66.64%" height="20px" class="letrasLogo"> <center>Reporte de Coordinación Aseguradora<br>Dirección de Servicios Publicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">REPORTE DE DAÑOS POR ACCIDENTE</th>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DATOS DE LA COORDINACIÓN ASEGURADORA</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">POSTE REFERENCIA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'poste', ['datosPoste']) || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NOMBRE AJUSTADOR</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${nombreAjustador}</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">TIPO DE ACCIDENTE</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt;">
        ${tipoAccidente}
        </span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">FECHA DE CREACIÓN</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${fechaCreacion}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">OBSERVACIÓN DE GENERAL</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${observacionGeneral}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">DAÑOS OCASIONADOS</th>
  </tr>
</table>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">POSTE</th>
  </tr>
</table>
<table class="Imagenes">
<tr>
  <th class="tituloDatos">TIPO</th>
  <th class="tituloDatos">DAÑO OCASIONADO</th>
</tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosPoste', 'tipoPoste']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosPoste', 'dañoPoste']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">OBSERVACIONES DE POSTE</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'observacionesPoste', ['datosPoste']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">BRAZO</th>
  </tr>
</table>
<table class="Imagenes">
<tr>
  <th class="tituloDatos">TAMAÑO</th>
  <th class="tituloDatos">CANTIDAD</th>
  <th class="tituloDatos">DAÑO OCASIONADO</th>
</tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosBrazo', 'tamañoBrazo']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosBrazo', 'cantidadBrazo']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosBrazo', 'dañoBrazo']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">OBSERVACIONES DEL BRAZO</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'observacionesBrazo', ['datosBrazo']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">LÁMPARA</th>
  </tr>
</table>
<table class="Imagenes">
<tr>
    <th class="tituloDatos">CANTIDAD</th>
  <th class="tituloDatos">POTENCIA</th>
  <th class="tituloDatos">DAÑO OCASIONADO</th>
</tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosLampara', 'cantidadLampara']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosLampara', 'potenciaLampara']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosLampara', 'dañoLampara']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">OBSERVACIONES DE LÁMPARA</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'observacionesLampara', ['datosLampara']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">TRAMOS DE TRIPLEX</th>
  </tr>
</table>
<table class="Imagenes">
<tr>
  <th class="tituloDatos">CANTIDAD DE TRAMOS</th>
  <th class="tituloDatos">METROS APROXIMADOS</th>
  <th class="tituloDatos">DAÑO OCASIONADO</th>
</tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosTriplex', 'cantidadTriplex']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosTriplex', 'metroTriplex']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosTriplex', 'dañoTriplex']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">OBSERVACIONES DE TRIPLEX</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'observacionesTriplex', ['datosTriplex']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">ACCESORIOS ADICIONALES</th>
  </tr>
</table>
<table class="Imagenes">
<tr>
  <th class="tituloDatos">ACCESORIOS ADICIONALES INSTALADOS EN EL PUNTO DEL ACCIDENTE</th>
  <th class="tituloDatos">DAÑO OCASIONADO</th>
</tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${accesoriosNombres}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'nombre', ['datosAccesorios', 'daños']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">OBSERVACIONES DE ACCESORIOS</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'observacionesAccesorios', ['datosAccesorios']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">RECONEXIONES</th>
  </tr>
</table>
<table class="Imagenes">
<tr>
  <th class="tituloDatos">CANTIDAD DE RECONEXIONES</th>
  <th class="tituloDatos">OBSERVACIONES DE RECONEXIONES</th>
</tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'cantidadReconexiones', ['datosReconexiones']) || ''}</span>
      </div>
    </td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${obtenerValorDetalle(detalle, 'observacionesReconexiones', ['datosReconexiones']) || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<br>
<br>
<br>
<br>
<div>
  <hr class="lineaFirma2">
</div>
<div class="texto">
  <p>${nombreAjustador}<br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
</div>
<br>
<div class="salto-pagina"/>
<br>
<table class="headLogos">
  <tr>
    <th width="66.64%" height="20px" class="letrasLogo"> <center>Reporte de Coordinación Aseguradora<br>Dirección de Servicios Publicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">REPORTE DE DAÑOS ADICIONALES POR ACCIDENTE</th>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DATOS DE QUIEN OCACIONO EL ACCIDENTE</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NOMBRE</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[0] && coordinacion.datosAdicionales[0]?.nombre || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">TELÉFONO</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[0] && coordinacion.datosAdicionales[0]?.telefono || ''}</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">DPI</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt;">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[0] && coordinacion.datosAdicionales[0]?.dpi || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">LICENCIA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[0] && coordinacion.datosAdicionales[0]?.licencia || ''}</span>
      </div>
    </td>
  </tr>
</table>
<table class="table">
  <tr>
  <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">DIRECCIÓN</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[0] && coordinacion.datosAdicionales[0]?.direccion || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DATOS DEL VEHICULO</th>
  </tr>
</table>
<table  class="table">
  <tr>
  <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">PROPIETARIO</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.propietario || ''}</span>
      </div>
    </td>
  <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NO. PLACA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.placa || ''}</span>
      </div>
    </td>
    </tr>
    <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">TIPO DE VEHICULO</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.tipo || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">MARCA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.marca || ''}</span>
      </div>
    </td>
    </tr>
    <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">MODELO</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.modelo || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">COLOR</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.color || ''}</span>
      </div>
    </td>
    </tr>
    <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">CUENTA CON SEGURO</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.seguro?.nombre || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">EMPRESA ASEGURADORA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[1] && coordinacion.datosAdicionales[1]?.aseguradora || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DIRECCIÓN DEL ACCIDENTE</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:left; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.direccion || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">DATOS ADICIONALES</th>
  </tr>
</table>
<table  class="table">
  <tr>
  <td style="padding-left: 15px; width: 28%; font-size:10.0pt;">MATRICULA DE POSTE</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.posteReferencia}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">CODIGO DE LÁMPARA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.codigoLampara || ''}</span>
      </div>
    </td>
    </tr>
    <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NOMBRE DE CORREDOR DE SEGUROS</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${nombreAjustador}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">HORA DE LLEGADA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.corredorSeguros?.horaLlegada || ''}</span>
      </div>
    </td>
    </tr>
    <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NOMBRE DE PERSONAL DE APVNSA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.apvnsaPersonal?.nombre || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">HORA DE LLEGADA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.apvnsaPersonal?.horaLlegada || ''}</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NOMBRE DE PERSONAL DE LUMINATA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.luminataPersonal?.nombre || ''}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">HORA DE LLEGADA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[2] && coordinacion.datosAdicionales[2]?.luminataPersonal?.horaLlegada || ''}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">ENTREGA A BODEGA</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 25%; font-size:10.0pt;">FIRMA DE RECEPCIÓN DE BODEGA</td>
      <td>
      <div class="linea">
        <br>
        <hr>
        <span style="text-align:center; text-transform: uppercase; font-size:10.0pt"></span>
      </div>
    </td>
   </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">CONTROL Y ORDEN DE SEGUIMIENTO DE ACCIDENTE</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 25%; font-size:10.0pt;">FOTOGRAFÍA O COPIA DE INFORME ACCIDENTE (SEGURO)</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.fotoSeguro ? 'SI' : 'NO'}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">ASISTENTE EN ACCIDENTE</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.asistenteSeguro || ''}</span>
      </div>
    </td>
   </tr>
   <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">INFORME TÉCNICO DE ACCIDENTE</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.informeAccidente ? 'SI' : 'NO'}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">ASISTENTE EN ACCIDENTE</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.asistenteAccidente || ''}</span>
      </div>
    </td>
   </tr>
   <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">REPORTE TÉCNICO DE ACCESORIOS</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.reporteAccesorios ? 'SI' : 'NO'}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">OPERACIONES Y BODEGA</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.operacionesBodega || ''}</span>
      </div>
    </td>
   </tr>
   <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">COTIZACIÓN DE LUMINARIA</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.cotizacionLuminaria ? 'SI' : 'NO'}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">CONTALIBILIDAD GENERAL</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.contabilidadGeneral || ''}</span>
      </div>
    </td>
   </tr>
   <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">COTIZACIÓN DE ACCESORIOS</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.cotizacionAccesorios ? 'SI' : 'NO'}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">CONTRATA</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.contrata || ''}</span>
      </div>
    </td>
   </tr>
   <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">CERTIFICACIÓN DE ACCIDENTE</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.certificacionAccidente ? 'SI' : 'NO'}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">CONTABILIDAD GENERAL</td>
      <td>
      <div class="linea">
        <hr>
        <span style="left: 50%; text-align:center; text-transform: uppercase; font-size:10.0pt">${coordinacion.datosAdicionales && coordinacion.datosAdicionales[3] && coordinacion.datosAdicionales[3]?.contabilidad || ''}</span>
      </div>
    </td>
   </tr>
</table>
<br>
<div class="salto-pagina"/>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">ANEXOS</th>
  </tr>
</table>
<br>
${coordinacion.seguimiento && coordinacion.seguimiento.length === 1 ? `
  <table class="tablaImagenesVFORSeguimientoUnico">
    <tbody>
      <tr>
        <td>
          <div class="tituloDatosImagenesSeguimientoUnico">${coordinacion.seguimiento[0].tipo}</div>
          <img src="${coordinacion.seguimiento[0].url}" alt="img_0">
        </td>
      </tr>
    </tbody>
  </table>
` : `
<table class="tablaImagenesVFORSeguimiento">
  <tbody>
    ${coordinacion.seguimiento && coordinacion.seguimiento.map((imagen, index) => `
      ${index % 2 === 0 ? '<tr>' : ''}
      <td>
        <div class="tituloDatosImagenesSeguimiento">${imagen.tipo}</div>
          <img src="${imagen.url}" alt="img_${index}">
      </td>
      ${index % 2 === 1 || index === coordinacion.seguimiento.length - 1 ? '</tr>' : ''}
    `)
    .join('')}
  </tbody>
</table>
`}
<div class="salto-pagina"/>
<br>
<table class="table">
  <tr>
    <th style="width: 100%" class="tituloDatos">ANEXOS</th>
  </tr>
</table>
<br>
<table class="tablaImagenesVFOR">
          <tbody>
            ${detalle && detalle.datosPoste ? `
              <tr>
                <td>
                  <div class="tituloDatosImagenes">Fotografía Daños al Poste</div>
                  <img src="${detalle.datosPoste && detalle.datosPoste.foto && detalle.datosPoste.foto.url}" alt="Fotografía Daños al Poste">
                </td>
                <td>
                  <div class="tituloDatosImagenes">Fotografía Daños al Brazo</div>
                  <img src="${detalle.datosBrazo.foto.url || ''}" alt="Fotografía Daños al Brazo">
                </td>
              </tr>
              <tr>
                <td>
                  <div class="tituloDatosImagenes">Fotografía Daños a la Lámpara</div>
                  <img src="${detalle.datosLampara.foto.url || ''}" alt="Fotografía Daños a la Lámpara">
                </td>
                <td>
                  <div class="tituloDatosImagenes">Fotografía Daños a Accesorios</div>
                  <img src="${detalle.datosAccesorios.foto.url || ''}" alt="Fotografía Daños a Accesorios">
                </td>
              </tr>
              <tr>
                <td>
                  <div class="tituloDatosImagenes">Fotografía Daños al Triplex</div>
                  <img src="${detalle.datosTriplex.foto.url || ''}" alt="Fotografía Daños al Triplex">
                </td>
                <td>
                  <div class="tituloDatosImagenes">Fotografía Daños a Reconexiones</div>
                  <img src="${detalle.datosReconexiones.foto.url || ''}" alt="Fotografía Daños a Reconexiones">
                </td>
              </tr>` : ''}
          </tbody>
        </table>
<br>
<br>
<br>
<br>
</div>
</body>
</html>
  `
}
