const accidentes = [
  {
    id: 1,
    nombre: 'Accidente',
  },
  {
    id: 2,
    nombre: 'Fenómeno Natural',
  },
]

const daños = [
  {
    id: 1,
    nombre: 'Daño Mínimo',
  },
  {
    id: 2,
    nombre: 'Daño Parcial',
  },
  {
    id: 3,
    nombre: 'Daño Total',
  },
  {
    id: 4,
    nombre: 'Perdida Total o Robo',
  },
]

// eslint-disable-next-line import/prefer-default-export
export function getListadoMantenimiento(tipo) {
  if (tipo === 1) return daños
  if (tipo === 2) return accidentes
  // if (tipo === 3) return dañosLampara
  // if (tipo === 4) return dañosTriplex
  // if (tipo === 5) return dañosAccesorios
  return []
}
