const accesorios = [
  {
    id: 1,
    nombre: 'Protección a Tierra',
    value: 'proteccionTierra',
  },
  {
    id: 2,
    nombre: 'Sistema de Control',
    value: 'sistemaControl',
  },
  {
    id: 3,
    nombre: 'Cámaras',
    value: 'camaras',
  },
  {
    id: 4,
    nombre: 'Transformadores',
    value: 'transformadores',
  },
]

const potencias = [
  {
    nombre: '30',
    value: 30,
  },
  {
    nombre: '50',
    value: 50,
  },
  {
    nombre: '80',
    value: 80,
  },
  {
    id: 4,
    nombre: '150',
    value: 150,
  },
]

const tramos = [
  {
    nombre: '1',
  },
  {
    nombre: '2',
  },
  {
    nombre: '3',
  },
  {
    nombre: 'Más',
  },
]

const metros = [
  {
    nombre: '1-20',
  },
  {
    nombre: '21-40',
  },
  {
    nombre: '41-60',
  },
  {
    nombre: 'Más de 60',
  },
]

// eslint-disable-next-line import/prefer-default-export
export function getListadoAccesorios(tipo) {
  if (tipo === 1) return accesorios
  if (tipo === 2) return potencias
  if (tipo === 3) return tramos
  if (tipo === 4) return metros
  return []
}
